/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash'
import * as yup from 'yup'
import { useMemo } from 'react'
import { useFormik } from 'formik'
import { useAuth } from '@contexts/Auth'
import { MyAccountContext } from './context'
import { useSnackbar } from '@contexts/Snackbar'
import { name, phone } from '@utils/forms/validation'
import { updateUser } from '@services/nomos_api/resources/user/update'
import { useModal } from '@contexts/Modal'
import { ConfirmPhoneModal } from './containers/ConfirmPhoneModal'

export type UsersProps = {
  children: React.ReactNode
}

export function MyAccountController({ children }: UsersProps) {
  const { user, updateUserData } = useAuth()
  const { openModal, cleanStack } = useModal()
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar()

  const validationSchema = yup.object().shape({
    name,
    phone,
  })

  const formik = useFormik({
    initialValues: {
      name: user?.name || '',
      email: user?.email || '',
      admin: user?.admin || false,
      phone: user?.phone || '',
      password: undefined,
      password_confirmation: undefined,
    },
    validationSchema,
    onSubmit: (values: any) => {
      // do not send phone to request if it was not updated
      if (values.phone === user?.phone) _.unset(values, 'phone')
      // update user with provided form data
      updateUser(values)
        .then(({ user, token_confirmation }) => {
          if (user.name || user.email) {
            updateUserData({ name: user.name, email: user.email })
          }
          if (token_confirmation) {
            openModal(
              <ConfirmPhoneModal
                token={token_confirmation}
                onUpdatedUser={(user) => {
                  cleanStack()
                  updateUserData({ phone: user.phone })
                  showSnackbarSuccess('Telefone confirmado com sucesso!')
                }}
              />,
              {
                lockscroll: true,
                lockclose: true,
              }
            )
          }
          showSnackbarSuccess('Dados atualizados com sucesso!')
        })
        .catch((error: any) => {
          showSnackbarError(
            error?.response?.data.message || 'Erro ao tentar atualizar os dados'
          )
        })
        .finally(() => {
          formik.setSubmitting(false)
        })
    },
  })

  const store = useMemo(
    () => ({
      formik,
    }),
    [formik]
  )

  return (
    <MyAccountContext.Provider value={store}>
      {children}
    </MyAccountContext.Provider>
  )
}
