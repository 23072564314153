import styled from 'styled-components'
import { Card } from '@components/atoms'

export const FeedbackCard = styled(Card)`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 32px;
  gap: 32px;
`

export const Media = styled.div`
  height: 120px;
  width: 120px;
  align-self: center;
`
