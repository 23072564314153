import * as Yup from 'yup'
import { useMemo } from 'react'
import { useFormik } from 'formik'
import { useAuth } from '@contexts/Auth'
import { SigninContext } from '../../context'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import { phone } from '@utils/forms/validation'
import { useSnackbar } from '@contexts/Snackbar'
import { FormPhoneIdentifierContext } from './context'
import { useContextSelector } from 'use-context-selector'
import { authSignInAPI } from '@services/nomos_api/resources/auth/signin'

import {
  FormPhoneIdentifierControllerProps,
  FormPhoneIdentifierFormType,
} from './types'

export function FormPhoneIdentifiderController({
  children,
}: FormPhoneIdentifierControllerProps): JSX.Element {
  const navigate = useNavigate()
  const { isAuthenticated } = useAuth()
  const { showSnackbarError } = useSnackbar()
  const formikCtx = useContextSelector(SigninContext, (s) => s.formikCtx)
  const formikRequest = useContextSelector(
    SigninContext,
    (s) => s.formikRequest
  )

  const handleNextStep = useContextSelector(
    SigninContext,
    (s) => s.handleNextStep
  )

  const formik = useFormik<FormPhoneIdentifierFormType>({
    initialValues: { phone: formikCtx.values.phone || '' },
    validationSchema: Yup.object().shape({ phone }),
    onSubmit({ phone }) {
      authSignInAPI({ phone })
        .then((response) => {
          if (response.kind === 'AUTHENTICATION' && !!response.data) {
            const lastPage = localStorage.getItem('@NOMOS:lastPage')
            isAuthenticated(response.data.user, response.data.jwt)
            navigate(lastPage || (isMobile ? 'search-results' : '/home'))
          } else {
            formikRequest.setFieldValue('exp', response.exp)
            formikRequest.setFieldValue('kind', response.kind)
            formikRequest.setFieldValue('request_id', response.request_id)
            formikCtx.setFieldValue('phone', phone)
            handleNextStep()
          }
        })
        .catch((error) => {
          showSnackbarError(error?.response?.data.message || '')
          formik.setErrors(error.response.data)
        })
        .finally(() => formik.setSubmitting(false))
    },
  })

  const state = useMemo(() => ({ formik }), [formik])
  return (
    <FormPhoneIdentifierContext.Provider value={state}>
      {children}
    </FormPhoneIdentifierContext.Provider>
  )
}
