import * as Yup from 'yup'
import { useMemo } from 'react'
import { useFormik } from 'formik'
import { phone } from '@utils/forms/validation'
import { useWhatsapp } from '@hooks/useWhatsapp'
import { ModalResendPhoneCodeContext } from './context'
import {
  ModalResendPhoneCodeControllerProps,
  ModalResendPhoneCodeFormType,
} from './types'
import { authSignInAPI } from '@services/nomos_api/resources/auth/signin'
import { useSnackbar } from '@contexts/Snackbar'

export function ModalResendPhoneCodeController({
  children,
  initialPhone,
  onPhoneValidated,
}: ModalResendPhoneCodeControllerProps): JSX.Element {
  const { handleSendMessage } = useWhatsapp()
  const { showSnackbarError } = useSnackbar()
  const handleTroubleAccess = () => {
    handleSendMessage(
      process.env.REACT_APP_PHONE_CONTACT!,
      [
        'Olá, estou com problemas para receber meu código de acesso por *WhatsApp*',
      ].join('\n')
    )
  }

  const formik = useFormik<ModalResendPhoneCodeFormType>({
    initialValues: { phone: initialPhone || '' },
    validationSchema: Yup.object().shape({ phone }),
    onSubmit({ phone }) {
      authSignInAPI({ phone })
        .then((response) => {
          onPhoneValidated(phone, response)
        })
        .catch((error) => {
          showSnackbarError(error?.response?.data.message || '')
          formik.setErrors(error.response.data)
        })
        .finally(() => formik.setSubmitting(false))
    },
  })

  const state = useMemo(
    () => ({ formik, handleTroubleAccess }),
    [formik, handleTroubleAccess]
  )
  return (
    <ModalResendPhoneCodeContext.Provider value={state}>
      {children}
    </ModalResendPhoneCodeContext.Provider>
  )
}
