/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext } from 'use-context-selector'

type MyAccountContextType = {
  formik: any
}

export const MyAccountContext = createContext<MyAccountContextType>(
  {} as MyAccountContextType
)
