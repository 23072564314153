import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Typography } from '@mui/material'
import { useState, useEffect } from 'react'
import { useSnackbar } from '@contexts/Snackbar'
import { AuthPage } from '@components/templates'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Input, TextLink } from '@components/atoms'
import { password, passwordConfirmation } from '@utils/forms/validation'
import { useResetPassword } from '@services/nomos_api/resources/reset_password'

import { Content, ResetPassowrdCard } from './styles'

function ResetPasswordPage() {
  const navigate = useNavigate()
  const { token } = useParams()

  const { showSnackbarSuccess, showSnackbarError } = useSnackbar()

  const { isLoading, data, error, mutate } = useResetPassword()

  const [tokenResetPassword, setTokenResetPassword] = useState('')

  const validationSchema = Yup.object().shape({
    password,
    password_confirmation: passwordConfirmation,
  })

  const formik = useFormik({
    initialValues: {
      password: '',
      password_confirmation: '',
    },
    validationSchema,
    onSubmit: (values) => mutate({ ...values, token: tokenResetPassword }),
  })

  useEffect(() => {
    if (!isLoading && data) {
      showSnackbarSuccess('Senha criada com sucesso')
      navigate('/')
    }
  }, [isLoading, data])

  useEffect(() => {
    if (!isLoading && error) {
      showSnackbarError(error?.response?.data.message || '')
    }
  }, [isLoading, error])

  useEffect(() => {
    if (token) {
      setTokenResetPassword(token)
    }
  }, [token])

  return (
    <AuthPage>
      <Content>
        <ResetPassowrdCard>
          <Typography
            data-testid="title"
            variant="$font-body-base"
            sx={{
              color: '#0A2156',
              fontWeight: 600,
              fontSize: '32px',
              lineHeight: '40px',
              letterSpacing: '-0.01em',
            }}
          >
            Crie sua nova senha de acesso
          </Typography>

          <Typography
            variant="subtitle1"
            sx={{
              color: '#858FA6',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '17px',
            }}
          >
            Crie uma nova senha e volte a ter acesso ao Nomos
          </Typography>

          <Input
            id="password"
            label="Senha"
            type="password"
            name="password"
            error={
              (formik.touched.password && Boolean(formik.errors.password)) ||
              (!isLoading && error)
            }
            helpertext={
              formik.values.password.length
                ? (formik.touched.password && formik.errors.password) ||
                  error?.response?.data?.message
                : 'A senha deve ter no mínimo 8 caracteres, 1 letra, 1 número e 1 caractere especial'
            }
            disabled={isLoading}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            onChange={formik.handleChange}
          />

          <Input
            id="password_confirmation"
            label="Confirmar Senha"
            type="password"
            name="password_confirmation"
            error={
              (formik.touched.password_confirmation &&
                Boolean(formik.errors.password_confirmation)) ||
              (!isLoading && error)
            }
            helpertext={
              (formik.touched.password_confirmation &&
                formik.errors.password_confirmation) ||
              error?.response?.data?.message
            }
            disabled={isLoading}
            onBlur={formik.handleBlur}
            value={formik.values.password_confirmation}
            onChange={formik.handleChange}
          />

          <Button
            type="submit"
            text="Confirmar"
            loading={isLoading}
            disabled={!(formik.isValid && formik.dirty)}
            onClick={() => formik.handleSubmit()}
          />

          <TextLink href="/" text="Fazer Login" />
        </ResetPassowrdCard>
      </Content>
    </AuthPage>
  )
}

export default ResetPasswordPage
