import { SignInForm } from '../../styles'
import { Stack, Typography } from '@mui/material'
import { Button, TextLink } from '@components/atoms'
import InputPhone from '@components/atoms/InputPhone'
import { FormPhoneIdentifierContext } from './context'
import { useContextSelector } from 'use-context-selector'

export function FormPhoneIdentifierView(): JSX.Element {
  const formik = useContextSelector(FormPhoneIdentifierContext, (s) => s.formik)
  return (
    <SignInForm>
      <Stack direction="column" spacing={1} alignItems="center">
        <Typography
          textAlign="center"
          variant="$font-title-3"
          color="$color-text-primary"
        >
          Entre ou crie sua conta
        </Typography>
        <Typography
          textAlign="center"
          variant="$font-body-base"
          color="$color-text-secundary"
        >
          Para sua conta ficar mais segura, vamos enviar um
          <br />
          código de confirmação
          {` `} <strong>no seu whatsApp</strong>
        </Typography>
      </Stack>
      <Stack direction="column" spacing={1} sx={{ width: '100%' }}>
        <InputPhone
          id="phone"
          size="small"
          name="phone"
          label="Informe seu número"
          placeholder="(__) _____-____"
          disabled={formik.isSubmitting}
          value={formik.values.phone}
          onBlur={formik.handleBlur}
          fullWidth
          onKeyUp={(e) => e.key === 'Enter' && formik.handleSubmit()}
          onChange={(value) => formik.setFieldValue('phone', value)}
          error={
            (formik.touched.phone &&
              formik.errors.phone) as unknown as undefined
          }
        />
        <Stack
          direction="column"
          alignItems={{ xs: 'flex-start', sm: 'center' }}
        >
          <Typography
            textAlign="center"
            variant="$font-body-base"
            color="$color-text-tertiary"
          >
            Ao continuar, você está de acordo com os
            <br />
          </Typography>
          <Stack direction={{ sx: 'column', sm: 'row' }} spacing={0.5}>
            <TextLink
              target="_blank"
              underline="hover"
              text="Termos de Uso"
              href="https://nomos-documentos.s3.sa-east-1.amazonaws.com/termos_de_uso.pdf"
            />
            <Typography
              variant="$font-body-base"
              color="$color-text-tertiary"
              sx={{ display: { xs: 'none', sm: 'block' } }}
            >
              e
            </Typography>
            <TextLink
              target="_blank"
              underline="hover"
              text="Política de Privacidade"
              href="https://nomos-documentos.s3.sa-east-1.amazonaws.com/politica_de_privacidade.pdf"
            />
          </Stack>
        </Stack>
      </Stack>
      <Button
        id="btn-submit-auth-phone"
        type="button"
        size="large"
        sx={{ px: 6, width: { xs: '100%', sm: 'auto' } }}
        variant="contained"
        text="Enviar código"
        onClick={() => formik.handleSubmit()}
        loading={formik.isSubmitting}
        disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
      />
    </SignInForm>
  )
}
