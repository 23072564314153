import { FormPhoneIdentifiderController } from './controller'
import { FormPhoneIdentifierProps } from './types'
import { FormPhoneIdentifierView } from './view'

export function FormPhoneIdentifier(
  props: FormPhoneIdentifierProps
): JSX.Element {
  return (
    <FormPhoneIdentifiderController {...props}>
      <FormPhoneIdentifierView />
    </FormPhoneIdentifiderController>
  )
}
