/* eslint-disable react/no-array-index-key */
import { SignInForm } from '../../styles'
import { Button, Input } from '@components/atoms'
import { FormRegistrationStep2Context } from './context'
import { useContextSelector } from 'use-context-selector'
import { MenuItem, Select, Stack, Typography } from '@mui/material'
import sectors from '@utils/forms/sectors.json'
import employeeOptions from '@utils/forms/employees.json'
import { ErrorLabel } from '@components/atoms/ErrorLabel'
import { SigninContext } from '../../context'
import { TextTimeOut } from '@components/atoms/TextTimeOut/TimeOut'
import _ from 'lodash'

export function FormRegistrationStep2View(): JSX.Element {
  const handlePrevStep = useContextSelector(
    SigninContext,
    (s) => s.handlePrevStep
  )

  const formikRequest = useContextSelector(
    SigninContext,
    (s) => s.formikRequest
  )

  const formik = useContextSelector(
    FormRegistrationStep2Context,
    (s) => s.formik
  )

  const handleOnExpire = useContextSelector(
    SigninContext,
    (s) => s.handleOnExpire
  )

  return (
    <SignInForm data-cy="signin-step-registration-company">
      <Stack direction="column" spacing={1} alignItems="center">
        <Typography
          textAlign="center"
          variant="$font-title-3"
          color="$color-text-primary"
        >
          Acesso verificado!
          <br />
          Conclua o seu cadastro.
        </Typography>
        <Typography
          textAlign="center"
          variant="$font-body-base"
          color="$color-text-secundary"
        >
          Falta muito pouco para você acessar sua conta.
          <br />
          Finalize o seu cadastro e tenha acesso total ao Nomos!
        </Typography>
      </Stack>
      <Stack direction="column" spacing={1} sx={{ width: '100%' }}>
        <Stack direction="column" spacing={1}>
          <Typography variant="$font-body-base" color="$color-text-primary">
            Nome da empresa
          </Typography>
          <Input
            id="companyName"
            name="companyName"
            size="small"
            placeholder="Digite o nome da empresa que trabalha"
            value={formik.values.companyName}
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.companyName && Boolean(formik.errors.companyName)
            }
            helpertext={formik.touched.companyName && formik.errors.companyName}
          />
        </Stack>
        <Stack direction="column" spacing={1}>
          <Typography variant="$font-body-base" color="$color-text-primary">
            Segmento de atuação
          </Typography>
          <Select
            id="companySector"
            name="companySector"
            size="small"
            value={formik.values.companySector}
            error={
              formik.touched.companySector &&
              Boolean(formik.errors.companySector)
            }
            data-cy="companySector"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            displayEmpty
            disabled={formik.isSubmitting}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return (
                  <Typography
                    variant="$font-body-base"
                    color="$color-text-tertiary"
                  >
                    Selecione uma das opções
                  </Typography>
                )
              }
              return _.get(sectors, selected)
            }}
          >
            {Object.keys(sectors).map((sectorKey, index) => (
              <MenuItem
                key={`sector-${sectorKey}`}
                value={sectorKey}
                data-cy={`companySector-option-${index}`}
              >
                <Typography variant="$font-body-base">
                  {_.get(sectors, sectorKey)}
                </Typography>
              </MenuItem>
            ))}
          </Select>
          <ErrorLabel
            value={
              (formik.touched.companySector &&
                formik.errors.companySector) as unknown as undefined
            }
          />
        </Stack>
        <Stack direction="column" spacing={1}>
          <Typography variant="$font-body-base" color="$color-text-primary">
            Número de funcionários
          </Typography>
          <Select
            id="companyEmployees"
            name="companyEmployees"
            size="small"
            value={formik.values.companyEmployees}
            error={
              formik.touched.companyEmployees &&
              Boolean(formik.errors.companyEmployees)
            }
            data-cy="companyEmployees"
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            displayEmpty
            renderValue={(selected) => {
              if (selected.length === 0) {
                return (
                  <Typography
                    variant="$font-body-base"
                    color="$color-text-tertiary"
                  >
                    Selecione uma das opções
                  </Typography>
                )
              }
              return selected
            }}
          >
            {employeeOptions.map((option, index) => (
              <MenuItem
                value={option}
                key={`companyEmployees-option-${index}`}
                data-cy={`companyEmployees-option-${index}`}
              >
                <Typography variant="$font-body-base">{option}</Typography>
              </MenuItem>
            ))}
          </Select>
          <ErrorLabel
            value={
              (formik.touched.companyEmployees &&
                formik.errors.companyEmployees) as unknown as undefined
            }
          />
        </Stack>
      </Stack>
      <Stack
        sx={{ width: '100%' }}
        spacing={2}
        direction={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        justifyContent="space-between"
      >
        <Stack direction="column" alignItems="flex-start">
          <Typography
            textAlign="left"
            variant="$font-body-base"
            color="$color-text-tertiary"
          >
            Passo 2 de 2
          </Typography>
          <TextTimeOut
            onTimedOut={handleOnExpire}
            expireTime={formikRequest.values.exp}
            countDownLabelTemplate="Sua sessão expira em {{time}}"
            timedOutLabelTemplate="Sua sessão expirou"
          />
        </Stack>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={1}
          alignItems="center"
          justifyContent="flex-end"
          sx={{ width: { xs: '100%', sm: 'auto' } }}
        >
          <Button
            type="button"
            size="medium"
            sx={{ px: 3, width: { xs: '100%', sm: 'auto' } }}
            variant="text"
            text="Voltar"
            onClick={handlePrevStep}
            disabled={formik.isSubmitting}
          />
          <Button
            type="button"
            size="medium"
            sx={{ px: 3, width: { xs: '100%', sm: 'auto' } }}
            variant="contained"
            text="Concluir"
            onClick={() => formik.handleSubmit()}
            loading={formik.isSubmitting}
            disabled={!formik.isValid || formik.isSubmitting}
            id="btn-submit-user-registration"
          />
        </Stack>
      </Stack>
    </SignInForm>
  )
}
