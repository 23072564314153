import * as Yup from 'yup'
import { useMemo } from 'react'
import { useFormik } from 'formik'
import { useAuth } from '@contexts/Auth'
import { SigninContext } from '../../context'
import { useNavigate } from 'react-router-dom'
import { useHubspot } from '@hooks/useHubspot'
import { useCookies } from '@contexts/Cookies'
import { isMobile } from 'react-device-detect'
import { useSnackbar } from '@contexts/Snackbar'
import { FormRegistrationStep2Context } from './context'
import { useContextSelector } from 'use-context-selector'
import { authSignUpAPI } from '@services/nomos_api/resources/auth/signup'
import { officeEployees, name, officeSectors } from '@utils/forms/validation'

import {
  FormRegistrationStep2ControllerProps,
  FormRegistrationStep2FormType,
} from './types'

export function FormRegistrationStep2Controller({
  children,
}: FormRegistrationStep2ControllerProps): JSX.Element {
  const navigate = useNavigate()
  const { getCookie } = useCookies()
  const { emitHsEvent } = useHubspot()
  const { isAuthenticated } = useAuth()
  const { showSnackbarError } = useSnackbar()
  const formikCtx = useContextSelector(SigninContext, (s) => s.formikCtx)
  const formikRequest = useContextSelector(
    SigninContext,
    (s) => s.formikRequest
  )

  const isInitialValid =
    !!formikCtx.values.company.name &&
    !!formikCtx.values.company.about.sector &&
    !!formikCtx.values.company.about.employees

  const formik = useFormik<FormRegistrationStep2FormType>({
    isInitialValid,
    initialValues: {
      companyName: formikCtx.values.company.name || '',
      companySector: formikCtx.values.company.about.sector || '',
      companyEmployees: formikCtx.values.company.about.employees || '',
    },
    validationSchema: Yup.object().shape({
      companyName: name,
      companySector: officeSectors,
      companyEmployees: officeEployees,
    }),
    onSubmit({ companyName, companySector, companyEmployees }) {
      formikCtx.setFieldValue('company.name', companyName)
      formikCtx.setFieldValue('company.about.sector', companySector)
      formikCtx.setFieldValue('company.about.employees', companyEmployees)

      authSignUpAPI({
        email: formikCtx.values?.email || '',
        name: formikCtx.values?.name || '',
        request_id: formikRequest.values?.request_id || '',
        request_code: formikCtx.values?.request_code || '',
        company: {
          name: companyName,
          about: {
            sector: companySector,
            employees: companyEmployees,
            officePosition: formikCtx.values.company.about.officePosition || '',
          },
          metadata: {
            page_url: window.location.href,
            page_title: document.title,
            hubspotutk: getCookie('hubspotutk'),
            utm_source: getCookie('utm_source'),
            utm_medium: getCookie('utm_medium'),
            utm_content: getCookie('utm_content'),
            utm_campaign: getCookie('utm_campaign'),
          },
        },
      })
        .then((userAuth) => {
          const lastPage = localStorage.getItem('@NOMOS:lastPage')
          isAuthenticated(userAuth.user, userAuth.jwt)
          navigate(lastPage || (isMobile ? 'search-results' : '/home'), {
            replace: true,
          })
          emitHsEvent({
            formName: 'nomos-signup',
            payload: {
              email: userAuth.user.email,
              phone: userAuth.user.phone,
              firstName: userAuth.user.name,
              company: userAuth.user.company?.name,
              jobtitle: userAuth.user.company?.about?.officePosition,
              origem: 'nomos::signup',
              formulario_de_origem: 'nomos',
            },
          })
        })
        .catch((error) => {
          showSnackbarError(error?.response?.data.message || '')
          formik.setErrors(error.response.data)
        })
        .finally(() => formik.setSubmitting(false))
    },
  })

  const state = useMemo(() => ({ formik }), [formik])

  return (
    <FormRegistrationStep2Context.Provider value={state}>
      {children}
    </FormRegistrationStep2Context.Provider>
  )
}
