import { Input } from '@components/atoms'
import { FiltersContainer } from './styles'
import { useDebounce } from '@hooks/useDebounce'
import { KeyValuePair } from '@utils/buckets/type'
import { StakeholdersContext } from '../../context'
import { useEffect, useRef, useState } from 'react'
import { useContextSelector } from 'use-context-selector'
import MultiSelect from '@components/atoms/MultiSelect/MultiSelect'
import { states } from '@services/dados_abertos/resources/estado_options'
import { useSearchOptions } from '@services/nomos_api/resources/search/options'

import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'

function FilterByName() {
  const firstUpdate = useRef(true)
  const [name, setName] = useState('')
  const debouncedName = useDebounce(name, 500)

  const handleFilterChange = useContextSelector(
    StakeholdersContext,
    (state) => state.handleFilterChange
  )

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    handleFilterChange({ name: debouncedName })
  }, [debouncedName])

  return (
    <Input
      size="small"
      type="search"
      id="stakeholder-search-name"
      label="Buscar por Stakeholder"
      value={name}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
      }}
    />
  )
}

function FilterByUF() {
  const firstUpdate = useRef(true)
  const [uf, setUF] = useState('')
  const debouncedUF = useDebounce(uf, 500)

  const handleFilterChange = useContextSelector(
    StakeholdersContext,
    (state) => state.handleFilterChange
  )

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    handleFilterChange({ uf: debouncedUF })
  }, [debouncedUF])

  return (
    <FormControl fullWidth sx={{ backgroundColor: 'white' }}>
      <InputLabel id="stakeholder-search-uf-label">UF</InputLabel>
      <Select
        size="small"
        id="stakeholder-search-uf"
        value={uf}
        label="UF"
        onChange={(e) => setUF(e.target.value)}
      >
        <MenuItem value="">Todos</MenuItem>

        {states.map((option) => (
          <MenuItem key={option.key} value={option.key}>
            {option.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

function FilterBySituation() {
  const firstUpdate = useRef(true)
  const [active, setActive] = useState<string>('true')

  const handleFilterChange = useContextSelector(
    StakeholdersContext,
    (state) => state.handleFilterChange
  )

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    handleFilterChange({ active })
  }, [active])

  return (
    <FormControl fullWidth sx={{ backgroundColor: 'white' }}>
      <InputLabel id="stakeholder-search-situation-label">Situação</InputLabel>
      <Select
        size="small"
        id="stakeholder-search-situation"
        value={active}
        label="Situação"
        onChange={(e) => setActive(e.target.value)}
      >
        <MenuItem value="">Todos</MenuItem>
        <MenuItem value="true">Ativos</MenuItem>
        <MenuItem value="false">Inativos</MenuItem>
      </Select>
    </FormControl>
  )
}

interface FilterByAcronymPartyProps {
  selectedHouses: string[]
}

function FilterByAcronymParty({ selectedHouses }: FilterByAcronymPartyProps) {
  const firstUpdate = useRef(true)
  const [acronymParty, setAcronymParty] = useState('')
  const debouncedAcronymParty = useDebounce(acronymParty, 500)

  const handleFilterChange = useContextSelector(
    StakeholdersContext,
    (state) => state.handleFilterChange
  )

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    handleFilterChange({ acronymParty: debouncedAcronymParty })
  }, [debouncedAcronymParty, selectedHouses])

  const { data, isFetching } = useSearchOptions(
    'stakeholders',
    'parties',
    selectedHouses
  )

  return (
    <FormControl fullWidth sx={{ backgroundColor: 'white' }}>
      <InputLabel id="stakeholder-search-acronym-party-label">
        Partído
      </InputLabel>
      <Select
        size="small"
        id="stakeholder-search-acronym-party"
        value={acronymParty}
        label="Partído"
        onChange={(e) => setAcronymParty(e.target.value)}
      >
        <MenuItem value="">Todos</MenuItem>

        {isFetching && <CircularProgress size={15} />}

        {(data as KeyValuePair[])?.map((option) => (
          <MenuItem key={option.key} value={option.key}>
            {option.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

interface FilterByHouseProps {
  onHouseChange: (houses: string[]) => void
}

function FilterByHouse({ onHouseChange }: FilterByHouseProps) {
  const firstUpdate = useRef(true)
  const [houses, setHouses] = useState<string[]>([])

  const handleFilterChange = useContextSelector(
    StakeholdersContext,
    (state) => state.handleFilterChange
  )

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    onHouseChange(houses)
    handleFilterChange({ openDataResource: houses })
  }, [houses, onHouseChange])

  return (
    <MultiSelect
      size="small"
      label="Casa"
      options={[
        { label: 'Camara dos Deputados', value: 'camara' },
        { label: 'Senado Federal', value: 'senado' },
        { label: 'ALMG', value: 'almg' },
        { label: 'ALESP', value: 'alesp' },
        { label: 'ALEAC', value: 'aleac' },
        { label: 'ALEAL', value: 'aleal' },
        { label: 'ALEAM', value: 'aleam' },
        { label: 'ALEMA', value: 'alema' },
        { label: 'ALERO', value: 'alero' },
        { label: 'ALEPI', value: 'alepi' },
        { label: 'ALEPE', value: 'alepe' },
        { label: 'ALETO', value: 'aleto' },
        { label: 'ALERR', value: 'alerr' },
        { label: 'ALESC', value: 'alesc' },
        { label: 'ALERJ', value: 'alerj' },
        { label: 'ALRS', value: 'alrs' },
        { label: 'ALEGO', value: 'alego' },
      ]}
      id="organ-search-resource"
      onChangeOptions={setHouses}
    />
  )
}

export default function StakeholdersFilter() {
  const [houses, setHouses] = useState<string[]>([])

  const handleHouseChange = (selectedHouses: string[]) => {
    setHouses(selectedHouses)
  }

  return (
    <FiltersContainer>
      <FilterByName />
      <FilterByHouse onHouseChange={handleHouseChange} />
      <FilterByAcronymParty selectedHouses={houses} />
      <FilterByUF />
      <FilterBySituation />
    </FiltersContainer>
  )
}
