import { FormPhoneConfirmationController } from './controller'
import { FormPhoneConfirmationType } from './types'
import { FormPhoneConfirmationView } from './view'

export function FormPhoneConfirmation(
  props: FormPhoneConfirmationType
): JSX.Element {
  return (
    <FormPhoneConfirmationController {...props}>
      <FormPhoneConfirmationView />
    </FormPhoneConfirmationController>
  )
}
