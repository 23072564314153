import { FeedbackContext } from './context'
import { AuthPage } from '@components/templates'
import { Feedback } from '@components/organisms/Cards'
import { useContextSelector } from 'use-context-selector'

import { Container } from './styles'

export function FeedbackView() {
  const data = useContextSelector(FeedbackContext, (s) => s.data)

  return (
    <AuthPage>
      <Container>
        <Feedback {...data} />
      </Container>
    </AuthPage>
  )
}
