/* eslint-disable react/no-array-index-key */
import {
  FormHelperText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import { Button, Input } from '@components/atoms'
import { useContextSelector } from 'use-context-selector'
import { FormRegistrationStep1Context } from './context'
import { SignInForm } from '../../styles'

import positions from '@utils/forms/positions.json'
import { ErrorLabel } from '@components/atoms/ErrorLabel'
import { TextTimeOut } from '@components/atoms/TextTimeOut/TimeOut'
import { SigninContext } from '../../context'

export function FormRegistrationStep1View(): JSX.Element {
  const formikReq = useContextSelector(SigninContext, (s) => s.formikRequest)
  const formik = useContextSelector(
    FormRegistrationStep1Context,
    (s) => s.formik
  )

  const handleOnExpire = useContextSelector(
    SigninContext,
    (s) => s.handleOnExpire
  )

  return (
    <SignInForm data-cy="signin-step-registration-user">
      <Stack direction="column" spacing={1} alignItems="center">
        <Typography
          textAlign="center"
          variant="$font-title-3"
          color="$color-text-primary"
        >
          Acesso verificado!
          <br />
          Conclua o seu cadastro.
        </Typography>
        <Typography
          textAlign="center"
          variant="$font-body-base"
          color="$color-text-secundary"
        >
          Falta muito pouco para você acessar sua conta.
          <br />
          Finalize o seu cadastro e tenha acesso total ao Nomos!
        </Typography>
      </Stack>
      <Stack direction="column" spacing={1} sx={{ width: '100%' }}>
        <Stack direction="column" spacing={1}>
          <Typography variant="$font-body-base" color="$color-text-primary">
            Nome e sobrenome
          </Typography>
          <Input
            id="name"
            name="name"
            size="small"
            placeholder="Digite seu nome"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helpertext={formik.touched.name && formik.errors.name}
          />
        </Stack>
        <Stack direction="column" spacing={1}>
          <Typography variant="$font-body-base" color="$color-text-primary">
            E-mail
          </Typography>
          <Input
            id="email"
            name="email"
            size="small"
            data-cy="email"
            placeholder="nome@empresa.com.br"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helpertext={formik.touched.email && formik.errors.email}
          />
          <FormHelperText>
            Insira o seu principal e-mail, é por ele que notificaremos sobre as
            atualizações dos dados que você monitora na plataforma.
          </FormHelperText>
        </Stack>
        <Stack direction="column" spacing={1}>
          <Typography variant="$font-body-base" color="$color-text-primary">
            Cargo
          </Typography>
          <Select
            size="small"
            id="officePosition"
            name="officePosition"
            data-cy="officePosition"
            value={formik.values.officePosition}
            error={
              formik.touched.officePosition &&
              Boolean(formik.errors.officePosition)
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            displayEmpty
            renderValue={(selected) => {
              if (selected.length === 0) {
                return (
                  <Typography
                    variant="$font-body-base"
                    color="$color-text-tertiary"
                  >
                    Selecione uma das opções
                  </Typography>
                )
              }
              return selected
            }}
          >
            {positions.map((position, index) => (
              <MenuItem
                value={position}
                key={`office-position-option-${index}`}
                data-cy={`office-position-option-${index}`}
              >
                <Typography variant="$font-body-base">{position}</Typography>
              </MenuItem>
            ))}
          </Select>
          <ErrorLabel
            value={
              (formik.touched.officePosition &&
                formik.errors.officePosition) as unknown as undefined
            }
          />
        </Stack>
      </Stack>
      <Stack
        sx={{ width: '100%' }}
        spacing={2}
        direction={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        justifyContent="space-between"
      >
        <Stack direction="column" alignItems="flex-start">
          <Typography
            textAlign="center"
            variant="$font-body-base"
            color="$color-text-tertiary"
          >
            Passo 1 de 2
          </Typography>
          <TextTimeOut
            onTimedOut={handleOnExpire}
            expireTime={formikReq.values.exp}
            countDownLabelTemplate="Sua sessão expira em {{time}}"
            timedOutLabelTemplate="Sua sessão expirou"
          />
        </Stack>
        <Button
          type="button"
          size="medium"
          sx={{ px: 3, width: { xs: '100%', sm: 'auto' } }}
          variant="outlined"
          text="Avançar"
          onClick={() => formik.handleSubmit()}
          disabled={!formik.isValid}
          id="btn-submit-next-step-registration"
        />
      </Stack>
    </SignInForm>
  )
}
