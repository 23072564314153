import { Box, Stack, styled } from '@mui/material'

export const SidePageRoot = styled(Stack)`
  width: 100%;
  height: 100%;
  position: relative;
  flex-direction: column;
`

export const SidePageWrapper = styled(Stack)`
  width: 100%;
  height: 100%;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
`

export const SidePagePull = styled(Box)<{ color: 'transparent' | 'white' }>(
  ({ color }) => ({
    backgroundColor: color,
    flex: 1,
  })
)

export const SidePageContainer = styled(Stack)`
  width: 88%;
  height: auto;
  position: relative;
  flex-direction: row;
  background-color: #ffffff;
  padding-left: 0px !important;
  padding-right: 0px !important;
`

export const SidePageLeftContainer = styled(Stack)(({ theme }) => ({
  height: '100%',
  padding: '0',
  backgroundColor: '#f9fafc',
  [theme.breakpoints.up('md')]: {
    width: '320px',
  },
}))

export const SidePageLeftContainerFixed = styled(Stack)(({ theme }) => ({
  width: '100%',
  position: 'fixed',
  bottom: '0',
  zIndex: 999,
  left: 0,
  [theme.breakpoints.up('md')]: {
    width: '320px',
    left: 'unset',
  },
}))

export const SidePageBodyContainer = styled(Stack)`
  flex: 1;
  padding-top: 46px;
  padding-left: 46px;
  padding-right: 0px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  overflow: auto;
`
