import * as Yup from 'yup'
import { useEffect } from 'react'
import { useFormik } from 'formik'
import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { email } from '@utils/forms/validation'
import { AuthPage } from '@components/templates'
import { Button, Input, TextLink } from '@components/atoms'
import { useForgotPassword } from '@services/nomos_api/resources/forgot_password'

import { Content, ForgetPasswordCard } from './styles'

function ForgotPasswordPage() {
  const navigate = useNavigate()
  const { isLoading, data, error, mutate } = useForgotPassword()

  const validationSchema = Yup.object().shape({
    email,
  })

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema,
    onSubmit: (values) => mutate(values),
  })

  useEffect(() => {
    if (!isLoading && data) {
      formik.resetForm()
      navigate('/feedback/reset-success')
    }
  }, [isLoading, error])

  useEffect(() => {
    if (!isLoading && error) {
      formik.resetForm()
      navigate('/feedback/reset-error')
    }
  }, [isLoading, error])

  return (
    <AuthPage>
      <Content>
        <ForgetPasswordCard>
          <Typography
            data-testid="title"
            variant="$font-title-2"
            color="$color-text-primary"
          >
            Redefinir senha
          </Typography>

          <Typography variant="$font-body-1" color="$color-text-secundary">
            Insira o endereço de email para o qual você gostaria que suas
            informações de redefinição de senha fossem enviadas.
          </Typography>

          <Input
            label="Email"
            type="email"
            name="email"
            error={formik?.touched.email && Boolean(formik.errors.email)}
            helpertext={formik?.touched.email && formik.errors.email}
            disabled={isLoading}
            onBlur={formik?.handleBlur}
            value={formik?.values.email}
            onChange={formik?.handleChange}
          />

          <Button
            type="submit"
            text="Enviar"
            disabled={!(formik?.isValid && formik.dirty)}
            loading={isLoading}
            onClick={() => formik?.handleSubmit()}
          />

          <TextLink href="/" text="Retornar para Login" />
        </ForgetPasswordCard>
      </Content>
    </AuthPage>
  )
}

export default ForgotPasswordPage
