import {
  SignInPresenterWrapper,
  SignInPresenterDescContainer,
  SignInPresenterDescItem,
  SignInPresenterDescItems,
  SignInPresenterHilight,
  SignInPresenterIllustration,
} from './styles'
import Typography from '@mui/material/Typography'
import CheckCircleSvg from '../../../../../assets/icons/check-circle.svg'

export function SignInPresentation(): JSX.Element {
  return (
    <SignInPresenterWrapper>
      <SignInPresenterDescContainer>
        <Typography color="$color-action-text-primary" variant="$font-title-3">
          Esteja{' '}
          <SignInPresenterHilight>um passo à frente</SignInPresenterHilight> na
          gestão regulatória{' '}
          <SignInPresenterHilight>do seu negócio</SignInPresenterHilight>
        </Typography>
        <SignInPresenterDescItems>
          <SignInPresenterDescItem>
            <img alt="Check Circle Icon" src={CheckCircleSvg} />
            <Typography variant="$font-body-base">
              <strong>Produtividade e Eficiência:</strong> Acesse diversas bases
              de dados como Diário Oficial da União e muito mais em um só lugar
            </Typography>
          </SignInPresenterDescItem>
          <SignInPresenterDescItem>
            <img alt="Check Circle Icon" src={CheckCircleSvg} />
            <Typography variant="$font-body-base">
              <strong>Informações contextualizadas:</strong> Busque termos-chave
              em seu setor com facilidade
            </Typography>
          </SignInPresenterDescItem>
          <SignInPresenterDescItem>
            <img alt="Check Circle Icon" src={CheckCircleSvg} />
            <Typography variant="$font-body-base">
              <strong>Dados acionáveis:</strong> Monitore agendas, stakeholders,
              órgãos do seu interesse e potencialize sua estratégia
            </Typography>
          </SignInPresenterDescItem>
          <SignInPresenterDescItem>
            <img alt="Check Circle Icon" src={CheckCircleSvg} />
            <Typography variant="$font-body-base">
              <strong>Atualizações instantâneas:</strong> Receba alertas sempre
              que um dado do seu interesse for encontrado ou atualizado
            </Typography>
          </SignInPresenterDescItem>
        </SignInPresenterDescItems>
      </SignInPresenterDescContainer>
      <SignInPresenterIllustration />
    </SignInPresenterWrapper>
  )
}
