import { useWhatsapp } from '@hooks/useWhatsapp'
import { Stack, Typography } from '@mui/material'
import { Button, TextLink } from '@components/atoms'
import { ModalStandard } from '@components/molecules'

export function ModalSessionExpired(): JSX.Element {
  const { handleSendMessage } = useWhatsapp()
  const handleTroubleAccess = () => {
    handleSendMessage(
      process.env.REACT_APP_PHONE_CONTACT!,
      [
        'Olá, estou tendo problemas com minha sessão ao tentar me autenticar na *Nomos*',
      ].join('\n')
    )
  }

  const handleReload = () => {
    window.location.reload()
  }

  return (
    <ModalStandard
      id="modal-signin-session-expired"
      title="Sua sessão expirou!"
      closeable={false}
      maxWidth={450}
    >
      <Stack spacing={3}>
        <Typography variant="$font-body-1" color="$color-text-secundary">
          Para garantir sua segurança, as nossas sessões de autenticação possuem
          um curto período de vida, e infelizmente esse período se esgotou.
        </Typography>
        <Button
          type="button"
          size="large"
          text="Reiniciar Sessão"
          onClick={() => handleReload()}
        />
        <Typography
          textAlign="center"
          variant="$font-body-base"
          color="$color-text-secundary"
        >
          Problemas para acessar ou criar a sua conta? Entre em
          <br />
          contato com nossa equipe através do link abaixo ou envie
          <br />
          um e-mail para: <strong>contato@nomosapp.com.br</strong>
        </Typography>
        <Stack alignItems="center">
          <TextLink
            onClick={handleTroubleAccess}
            text="Falar com especialista"
          />
        </Stack>
      </Stack>
    </ModalStandard>
  )
}
