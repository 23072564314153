/* eslint-disable no-alert */
import { useAuth } from '@contexts/Auth'
import { useModal } from '@contexts/Modal'
import { Logout } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { CaretDown } from '@phosphor-icons/react'
import { ArrowDropDown } from '@mui/icons-material'
import { SVGProps, useMemo, useState } from 'react'
import { ShareCheckoutModal } from '@containers/Modals'
import { NomosModuleEnumKeys } from '@enums/NomosModuleEnum'
import { AppBannerUnpaid } from '@containers/AppBannerUnpaid'
import { AppBannerPastdue } from '@containers/AppBannerPastdue'
import { StripePortalLink } from '@containers/StripePortalLink'
import { NotificationBadge } from '@containers/NotificationBadge'
import { SizedComponent, TypographyWrap } from '@components/atoms'
import { Toolbar, Typography, Menu, MenuItem, Stack } from '@mui/material'
import AppBannerFreeTrialExpiration from '@containers/AppBannerFreeTrialExpiration'

import {
  HelpIcon,
  InsightsProduct,
  MonitorProduct,
  OrganoGovProduct,
  PlusUserIcon,
  UserIcon,
} from '@components/icons'

import {
  Menus,
  MenuButton,
  BoxProducts,
  ProductLink,
  LogoContainer,
  MenuContainer,
  ApplicationBar,
  MenuItemWrapper,
} from './AppBar.styles'

import { DesktopMenu } from './components/DesktopMenu/DesktopMenu'
import { MobileMenu } from './components/MobileMenu/MobileMenu'
import { WhatsAppPaymentLink } from '@containers/WhatsAppPaymentLink'

export type NomosModuleProps = {
  id: string
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element
  name: string
  path: string
  options?: [] | undefined
}

export type Page = {
  name: string
  path: string
  options: string[] | undefined
}

export type AppBarComponentProps = {
  module: NomosModuleEnumKeys
  logo: React.ReactElement
  pages?: Page[]
  onChangeHeight?: (height: number) => void
}

function AppBarComponent({
  module,
  logo,
  pages,
  onChangeHeight,
}: AppBarComponentProps) {
  const { openModal } = useModal()
  const navigate = useNavigate()
  const { user, isLogout } = useAuth()

  const [anchorElProducts, setAnchorElProducts] = useState<
    Element | null | undefined
  >(null)

  const [anchorElUser, setAnchorElUser] = useState<Element | null | undefined>(
    null
  )

  const products: Record<NomosModuleEnumKeys, NomosModuleProps> = {
    monitor: {
      id: 'monitor',
      icon: MonitorProduct,
      name: 'Nomos Monitoramento',
      path: 'home',
      options: undefined,
    },
    organogov: {
      id: 'organogov',
      icon: OrganoGovProduct,
      name: 'Nomos Organogov',
      path: 'organogov',
      options: [],
    },
    insights: {
      id: 'insights',
      icon: InsightsProduct,
      name: 'Nomos Insights',
      path: 'insights',
      options: [],
    },
  }

  const settings = [
    {
      id: 'my-account',
      title: 'Minha conta',
      icon: <UserIcon />,
      onClick: () => navigate('/my-account'),
    },
    {
      id: 'users',
      title: 'Usuários',
      icon: <PlusUserIcon />,
      onClick: () => navigate('/users'),
    },
    {
      id: 'logout',
      title: 'Logout',
      icon: <Logout fontSize="small" />,
      onClick: isLogout,
    },
  ]

  const handleOpenProductsMenu = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorElProducts(event.currentTarget)
  }

  const handleOpenUserMenu = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseProductsMenu = () => {
    setAnchorElProducts(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const selectedProduct = useMemo(() => {
    return products[module]
  }, [module])

  return (
    <ApplicationBar data-testid="appbar-component" className="notPrintable">
      <SizedComponent onChange={({ height }) => onChangeHeight?.(height)}>
        <>
          <AppBannerPastdue />
          <AppBannerUnpaid />
          <AppBannerFreeTrialExpiration />
          <Stack
            width="88%"
            margin="0 auto"
            justifyContent="center"
            sx={{ height: 80 }}
          >
            <Toolbar disableGutters>
              {/* For mobile */}
              <MobileMenu pages={pages || []} />
              {/* For desktop */}
              <BoxProducts>
                <MenuButton
                  size="small"
                  color="secondary"
                  disableRipple={false}
                  data-cy="menu-products"
                  aria-label="products options"
                  aria-controls="menu-products"
                  aria-haspopup="true"
                  onClick={handleOpenProductsMenu}
                >
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <selectedProduct.icon width={28} height={28} />
                    <CaretDown weight="fill" color="#2D3A40" size={11} />
                  </Stack>
                </MenuButton>

                <Menu
                  sx={{ mt: '45px' }}
                  data-testid="menu-products"
                  anchorEl={anchorElProducts}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElProducts)}
                  onClose={handleCloseProductsMenu}
                >
                  {Object.values(products).map((product, index) => (
                    <MenuItem
                      key={product.path}
                      data-cy={product.id}
                      onClick={handleCloseProductsMenu}
                    >
                      <ProductLink
                        to={product.path}
                        data-testid={`product-tab-${index}`}
                      >
                        <product.icon width={28} height={28} />
                        <Typography
                          variant="$font-body-base"
                          color="$color-text-secundary"
                        >
                          {product.name}
                        </Typography>
                      </ProductLink>
                    </MenuItem>
                  ))}
                </Menu>
              </BoxProducts>

              <MenuButton
                size="small"
                target="_blank"
                color="secondary"
                disableRipple={false}
                onClick={() => navigate('/home')}
              >
                <LogoContainer>{logo}</LogoContainer>
              </MenuButton>

              {/* For desktop  */}
              <DesktopMenu pages={pages || []} />

              {/* Desktop only end  */}
              <Menus>
                <MenuContainer>
                  <NotificationBadge />

                  {!isMobile && (
                    <>
                      <MenuButton
                        size="small"
                        color="secondary"
                        disableRipple={false}
                        onClick={handleOpenUserMenu}
                        data-cy="menu-appbar"
                      >
                        <Stack
                          pl={0.5}
                          spacing={0.5}
                          direction="row"
                          alignItems="center"
                        >
                          <TypographyWrap
                            variant="$font-body-2"
                            color="$color-text-primary"
                            maxWidth={150}
                          >
                            {user?.name}
                          </TypographyWrap>
                          <ArrowDropDown color="primary" />
                        </Stack>
                      </MenuButton>

                      <Menu
                        id="menu-appbar"
                        sx={{ mt: '45px' }}
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                      >
                        {settings.map((setting) => (
                          <MenuItemWrapper
                            key={setting.id}
                            data-cy={setting.id}
                            onClick={() => {
                              setting.onClick()
                              handleCloseUserMenu()
                            }}
                          >
                            {setting.icon}
                            <Typography
                              variant="$font-body-base"
                              color="$color-text-secundary"
                            >
                              {setting.title}
                            </Typography>
                          </MenuItemWrapper>
                        ))}

                        {!!user?.admin && !user?.company?.subscription && (
                          <MenuItemWrapper>
                            <WhatsAppPaymentLink
                              fullWidth
                              size="small"
                              text="Assinar Plano Pro"
                              title="Assinar Plano Pro"
                            />
                          </MenuItemWrapper>
                        )}

                        {!!user?.admin && !user?.company?.subscription && (
                          <MenuItemWrapper
                            onClick={() => {
                              openModal(<ShareCheckoutModal />)
                              handleCloseUserMenu()
                            }}
                          >
                            <Typography
                              variant="$font-body-3"
                              color="$color-action-link"
                            >
                              Compartilhar link de assinatura
                            </Typography>
                          </MenuItemWrapper>
                        )}

                        {!!user?.admin && user?.company?.subscription && (
                          <MenuItemWrapper>
                            <StripePortalLink
                              size="small"
                              text="Visualizar Assinatura"
                              title="Visualizar Assinatura"
                            />
                          </MenuItemWrapper>
                        )}
                      </Menu>
                    </>
                  )}
                </MenuContainer>

                <MenuContainer>
                  <MenuButton
                    size="small"
                    target="_blank"
                    color="secondary"
                    disableRipple={false}
                    href="https://www.conteudo.nomosapp.com.br/faq"
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      <HelpIcon />
                      <Typography
                        variant="$font-body-2"
                        color="$color-text-primary"
                      >
                        Ajuda
                      </Typography>
                    </Stack>
                  </MenuButton>
                </MenuContainer>
              </Menus>
            </Toolbar>
          </Stack>
        </>
      </SizedComponent>
    </ApplicationBar>
  )
}

AppBarComponent.defaultProps = {
  pages: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  onChangeHeight: (height: number) => {},
}

export default AppBarComponent
