import IconCircleSolidError from '@assets/icons/circle-solid-error.svg'
import IconCircleSolidSuccess from '@assets/icons/circle-solid-success.svg'
import IconCircleSolidWarning from '@assets/icons/circle-solid-warning.svg'

import { FeedbackProps } from '@components/organisms/Cards/Feedback/Feedback'

export default {
  mobile: {
    icon: IconCircleSolidWarning,
    title: 'Aplicação não disponível para esta dimensão de tela',
    text: 'Estamos trabalhando para melhorar a experiência de uso em dispositivos móveis e tablets. Para ter a melhor experiência possível, recomendamos que você acesse o Nomos em um desktop.',
  },
  'reset-success': {
    icon: IconCircleSolidSuccess,
    title: 'Verifique seu email',
    text: 'Envimos um email com instruções para você cadastrar sua nova senha',
    action: {
      path: '/',
      text: 'Retornar para a tela de Login',
    },
  },
  'reset-error': {
    icon: IconCircleSolidError,
    title: 'Email inválido',
    text: 'Parece que há algo de errado com o email informado! Informe um email válido ou entre em contato conosco',
    action: {
      path: '/forgot-password',
      text: 'Informar um novo email',
    },
  },
} as Record<string, FeedbackProps>
