import { ModalResendPhoneCodeController } from './controller'
import { ModalResendPhoneCodeProps } from './types'
import { ModalResendPhoneCodeView } from './view'

export function ModalResendPhoneCode(
  props: ModalResendPhoneCodeProps
): JSX.Element {
  return (
    <ModalResendPhoneCodeController {...props}>
      <ModalResendPhoneCodeView />
    </ModalResendPhoneCodeController>
  )
}
