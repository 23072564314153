import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useAuth } from '@contexts/Auth'
import { useModal } from '@contexts/Modal'
import { useCallback, useMemo } from 'react'
import { SigninContext } from '../../context'
import { isMobile } from 'react-device-detect'
import { useSnackbar } from '@contexts/Snackbar'
import { FormPhoneConfirmationContext } from './context'
import { useContextSelector } from 'use-context-selector'
import { useNavigate, useParams } from 'react-router-dom'
import { ModalResendPhoneCode } from '../ModalResendPhoneCode'
import { authSignAuthAPI } from '@services/nomos_api/resources/auth/signauth'
import {
  FormPhoneConfirmationControllerProps,
  FormPhoneConfirmationFormType,
} from './types'

export function FormPhoneConfirmationController({
  children,
}: FormPhoneConfirmationControllerProps): JSX.Element {
  const navigate = useNavigate()
  const { inviteId, inviteCode } = useParams<{
    inviteId?: string
    inviteCode?: string
  }>()
  const { isAuthenticated } = useAuth()
  const { openModal, cleanStack } = useModal()
  const { showSnackbarError } = useSnackbar()
  const formikCtx = useContextSelector(SigninContext, (s) => s.formikCtx)
  const formikRequest = useContextSelector(
    SigninContext,
    (s) => s.formikRequest
  )

  const handleNextStep = useContextSelector(
    SigninContext,
    (s) => s.handleNextStep
  )

  const formik = useFormik<FormPhoneConfirmationFormType>({
    initialValues: {
      request_code: formikCtx.values.request_code || '',
      request_id: formikRequest.values.request_id || '',
      request_invite_id: inviteId,
      request_invite_code: inviteCode,
    },
    validationSchema: Yup.object({
      request_code: Yup.string()
        .min(5, 'Deve conter 5 caracteres')
        .max(5, 'Deve conter 5 caracteres')
        .required('Código obrigatório'),
    }),
    onSubmit(payload) {
      authSignAuthAPI(payload)
        .then((response) => {
          if (response.kind === 'AUTHENTICATION') {
            const lastPage = localStorage.getItem('@NOMOS:lastPage')
            isAuthenticated(response.data.user, response.data.jwt)
            navigate(lastPage || (isMobile ? 'search-results' : '/home'))
          } else if (response.kind === 'REGISTRATION') {
            formikCtx.setFieldValue('request_code', payload.request_code)
            formikRequest.setFieldValue('exp', response.data.exp)
            formikRequest.setFieldValue('kind', response.data.kind)
            formikRequest.setFieldValue('request_id', response.data.request_id)
            handleNextStep()
          }
        })
        .catch((error) => {
          showSnackbarError(
            error?.response?.data.title || '',
            error?.response?.data.message || ''
          )
          formik.setErrors(error.response.data)
        })
        .finally(() => formik.setSubmitting(false))
    },
  })

  const handleRetry = useCallback(() => {
    openModal(
      <ModalResendPhoneCode
        initialPhone={formikCtx.values.phone}
        onPhoneValidated={(phone, authResponse) => {
          cleanStack()
          formikCtx.setFieldValue('phone', phone)
          formikRequest.setFieldValue('exp', authResponse.exp)
          formikRequest.setFieldValue('kind', authResponse.kind)
          formikRequest.setFieldValue('request_id', authResponse.request_id)
          formik.setFieldValue('request_id', authResponse.request_id)
        }}
      />
    )
  }, [formikCtx])

  const state = useMemo(() => ({ formik, handleRetry }), [formik, handleRetry])
  return (
    <FormPhoneConfirmationContext.Provider value={state}>
      {children}
    </FormPhoneConfirmationContext.Provider>
  )
}
