import { AlertColor } from '@mui/material'
import { theme } from '@theme/index'
import { useState, useMemo, createContext, useContext } from 'react'

type SnackbarOptions = {
  position: 'right' | 'left' | 'center'
}

type SnackbarProps = {
  show: boolean
  type: AlertColor
  title: string
  message?: string
  color: string
  position?: 'right' | 'left' | 'center'
}

type ContextType = {
  snackbar: SnackbarProps
  closeSnackbar: () => void
  showSnackbarInfo: (
    title: string,
    message?: string,
    options?: SnackbarOptions
  ) => void
  showSnackbarError: (
    title: string,
    message?: string,
    options?: SnackbarOptions
  ) => void
  showSnackbarSuccess: (
    title: string,
    message?: string,
    options?: SnackbarOptions
  ) => void
  showSnackbarWarning: (
    title: string,
    message?: string,
    options?: SnackbarOptions
  ) => void
}

const Context = createContext<ContextType>({} as ContextType)

type SnackBarProviderProps = {
  children: React.ReactNode
}

function SnackBarProvider({ children }: SnackBarProviderProps) {
  const [snackbar, setSnackbar] = useState<SnackbarProps>({
    show: false,
    type: 'success',
    title: '',
    message: undefined,
    color: theme.palette['$color-semantic-success'],
  })

  const showSnackbarInfo = (
    title: string,
    message?: string,
    options?: SnackbarOptions
  ) => {
    setSnackbar({
      show: true,
      type: 'info',
      title,
      message,
      color: theme.palette['$color-semantic-message'],
      position: options?.position,
    })
  }

  const showSnackbarSuccess = (
    title: string,
    message?: string,
    options?: SnackbarOptions
  ) => {
    setSnackbar({
      show: true,
      type: 'success',
      title,
      message,
      color: theme.palette['$color-semantic-success'],
      position: options?.position,
    })
  }

  const showSnackbarError = (
    title: string,
    message?: string,
    options?: SnackbarOptions
  ) => {
    setSnackbar({
      show: true,
      type: 'error',
      title,
      message,
      color: theme.palette['$color-semantic-error'],
      position: options?.position,
    })
  }

  const showSnackbarWarning = (
    title: string,
    message?: string,
    options?: SnackbarOptions
  ) => {
    setSnackbar({
      show: true,
      type: 'warning',
      title,
      message,
      color: theme.palette['$color-semantic-warning'],
      position: options?.position,
    })
  }

  const closeSnackbar = () => {
    setSnackbar({ ...snackbar, show: false })
  }

  const snackbarProviderValues = useMemo(
    () => ({
      snackbar,
      closeSnackbar,
      showSnackbarInfo,
      showSnackbarError,
      showSnackbarSuccess,
      showSnackbarWarning,
    }),
    [
      snackbar,
      closeSnackbar,
      showSnackbarInfo,
      showSnackbarError,
      showSnackbarSuccess,
      showSnackbarWarning,
    ]
  )

  return (
    <Context.Provider value={snackbarProviderValues}>
      {children}
    </Context.Provider>
  )
}

const useSnackbar = () => {
  const context = useContext(Context)
  return context
}

export { SnackBarProvider, useSnackbar }
