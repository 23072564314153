import { useFormik } from 'formik'
import { SigninContext } from './context'
import { useModal } from '@contexts/Modal'
import { useCallback, useMemo, useState } from 'react'
import { ModalSessionExpired } from './containers/ModalSessionExpired'
import { SigninControllerProps, SigninFormType, SigninSteps } from './types'
import { AuthResponseSignInType } from '@services/nomos_api/types/auth_response_signin_type'

export function SigninController({
  children,
}: SigninControllerProps): JSX.Element {
  const { openModal } = useModal()
  const [step, setStep] = useState<SigninSteps>('phone_identifier')

  const handlePrevStep = useCallback(() => {
    switch (step) {
      case 'registration_step_2':
        return setStep('registration_step_1')
      case 'registration_step_1':
        return setStep('phone_confirmation')
      case 'phone_confirmation':
        return setStep('phone_identifier')
      default:
        return null
    }
  }, [step])

  const handleNextStep = useCallback(() => {
    switch (step) {
      case 'phone_identifier':
        return setStep('phone_confirmation')
      case 'phone_confirmation':
        return setStep('registration_step_1')
      case 'registration_step_1':
        return setStep('registration_step_2')
      default:
        return null
    }
  }, [step])

  const formikRequest = useFormik<AuthResponseSignInType>({
    initialValues: {
      exp: 0,
      request_id: '',
      kind: 'AUTHENTICATION',
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit() {},
  })

  const formikCtx = useFormik<SigninFormType>({
    initialValues: {
      request_id: '',
      request_code: '',
      phone: '',
      email: '',
      name: '',
      company: {
        name: '',
        about: {
          officePosition: '',
          employees: '',
          sector: '',
        },
      },
    },
    onSubmit() {
      handleNextStep()
    },
  })

  const handleOnExpire = () => {
    openModal(<ModalSessionExpired />, { lockclose: true })
  }

  const state = useMemo(
    () => ({
      formikCtx,
      formikRequest,
      step,
      handleNextStep,
      handlePrevStep,
      handleOnExpire,
    }),
    [
      formikCtx,
      formikRequest,
      step,
      handleNextStep,
      handlePrevStep,
      handleOnExpire,
    ]
  )

  return (
    <SigninContext.Provider value={state}>{children}</SigninContext.Provider>
  )
}
