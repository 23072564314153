import {
  SignInWrapper,
  SignInMainForm,
  SignInFormContainer,
  SignInFormWrapper,
  SignInFormContainerPadding,
  SignInFormSpacer,
} from './styles'

import { SignInFormSelector } from './containers/FormSelector'
import { SignInPresentation } from './containers/SignInPresentation'
import LogoNomosArkoSvg from '../../../assets/logos/logo-nomos-arko.svg'

export function SigninView(): JSX.Element {
  return (
    <SignInWrapper>
      <SignInPresentation />
      <SignInMainForm>
        <SignInFormSpacer />
        <SignInFormWrapper>
          <img
            height={54}
            src={LogoNomosArkoSvg}
            alt="Logotipo Nomos com slogan Arko"
          />
          <SignInFormContainer>
            <SignInFormContainerPadding>
              <SignInFormSelector />
            </SignInFormContainerPadding>
          </SignInFormContainer>
        </SignInFormWrapper>
        <SignInFormSpacer />
      </SignInMainForm>
    </SignInWrapper>
  )
}
