import HTTPClient from '../..'
import { useMutation } from '@tanstack/react-query'
import { AxiosErrorResponse } from '@services/nomos_api/entities/axios'
import { FormSignInType } from '@services/nomos_api/types/form_signin_type'
import { AuthResponseSignInType } from '@services/nomos_api/types/auth_response_signin_type'

export const authSignInAPI = async (body: FormSignInType) => {
  const { data } = await HTTPClient.post<AuthResponseSignInType>(
    '/signin',
    body
  )
  return data
}

export const useAuthSignIn = () => {
  return useMutation<
    AuthResponseSignInType,
    AxiosErrorResponse,
    FormSignInType
  >(['auth-signin'], authSignInAPI)
}
