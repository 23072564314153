import { SVGProps } from 'react'

function InsightsProductIcon({
  width = 279,
  height = 279,
  fill = 'none',
}: SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox="0 0 279 279" fill={fill}>
      <rect width="279" height="279" rx="40" fill="#4070F4" />
      <circle
        opacity="0.7"
        cx="144.189"
        cy="52.0847"
        r="10.2341"
        fill="white"
      />
      <circle
        opacity="0.7"
        cx="170.627"
        cy="56.3492"
        r="10.2341"
        fill="white"
      />
      <circle
        opacity="0.7"
        cx="192.802"
        cy="69.1413"
        r="10.2341"
        fill="white"
      />
      <circle
        opacity="0.7"
        cx="209.858"
        cy="87.9048"
        r="10.2341"
        fill="white"
      />
      <circle
        opacity="0.7"
        cx="221.799"
        cy="110.931"
        r="10.2341"
        fill="white"
      />
      <circle opacity="0.7" cx="226.915" cy="137.37" r="10.2341" fill="white" />
      <circle
        opacity="0.7"
        cx="221.799"
        cy="163.809"
        r="10.2341"
        fill="white"
      />
      <circle
        opacity="0.7"
        cx="211.563"
        cy="186.831"
        r="10.2341"
        fill="white"
      />
      <circle
        opacity="0.7"
        cx="194.507"
        cy="205.592"
        r="10.2341"
        fill="white"
      />
      <circle
        opacity="0.7"
        cx="172.333"
        cy="219.241"
        r="10.2341"
        fill="white"
      />
      <circle
        opacity="0.7"
        cx="146.749"
        cy="226.064"
        r="10.2341"
        fill="white"
      />
      <circle
        opacity="0.7"
        cx="120.312"
        cy="224.361"
        r="10.2341"
        fill="white"
      />
      <circle
        opacity="0.7"
        cx="94.7265"
        cy="215.834"
        r="10.2341"
        fill="white"
      />
      <circle
        opacity="0.7"
        cx="75.9618"
        cy="198.772"
        r="10.2341"
        fill="white"
      />
      <circle
        opacity="0.7"
        cx="60.6115"
        cy="178.303"
        r="10.2341"
        fill="white"
      />
      <circle
        opacity="0.7"
        cx="52.0829"
        cy="153.571"
        r="10.2341"
        fill="white"
      />
      <circle
        opacity="0.7"
        cx="52.0829"
        cy="127.134"
        r="10.2341"
        fill="white"
      />
      <circle
        opacity="0.7"
        cx="58.9054"
        cy="100.699"
        r="10.2341"
        fill="white"
      />
      <circle opacity="0.7" cx="74.259" cy="79.3722" r="10.2341" fill="white" />
      <circle opacity="0.7" cx="93.019" cy="64.0264" r="10.2341" fill="white" />
      <circle
        opacity="0.7"
        cx="116.899"
        cy="53.7923"
        r="10.2341"
        fill="white"
      />
      <circle cx="145.896" cy="97.2847" r="10.2341" fill="white" />
      <circle cx="119.457" cy="100.699" r="10.2341" fill="white" />
      <circle cx="101.549" cy="118.607" r="10.2341" fill="white" />
      <circle cx="97.2841" cy="145.899" r="10.2341" fill="white" />
      <circle cx="109.224" cy="168.071" r="10.2341" fill="white" />
      <circle cx="132.249" cy="180.01" r="10.2341" fill="white" />
      <circle cx="157.835" cy="175.747" r="10.2341" fill="white" />
      <circle cx="175.745" cy="158.691" r="10.2341" fill="white" />
      <circle cx="180.01" cy="131.399" r="10.2341" fill="white" />
      <circle cx="168.922" cy="109.227" r="10.2341" fill="white" />
      <circle cx="139.073" cy="139.073" r="10.2341" fill="white" />
    </svg>
  )
}
export default InsightsProductIcon
