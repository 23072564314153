/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/destructuring-assignment */
import { useState } from 'react'
import { VisibilityOff, Visibility, Search } from '@mui/icons-material'

import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  Checkbox,
  Radio,
} from '@mui/material'

import {
  CheckboxActiveIcon,
  CheckboxIcon,
  InputCheckboxLabel,
} from './Input.styles'
import _ from 'lodash'

function InputComponent(props: any) {
  const {
    id = 'input-component',
    type = 'text',
    label,
    disabled,
    error,
    helpertext,
    size,
  } = props

  const [showPassword, setShowPassword] = useState(false)

  if (type === 'password') {
    return (
      <FormControl variant="outlined" size={size} fullWidth {...props}>
        <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
        <OutlinedInput
          {...props}
          size={size}
          data-testid={id}
          type={showPassword ? 'text' : 'password'}
          inputprops={{
            'data-cy': `${id}-input`,
            'data-testid': `${id}-input`,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? (
                    <Visibility sx={{ color: error && '#d32f2f' }} />
                  ) : (
                    <VisibilityOff sx={{ color: error && '#d32f2f' }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {helpertext?.length && (
          <FormHelperText
            {...props}
            sx={{ ml: 0, color: '#3C4C70' }}
            data-cy={`${id}-error-msg`}
          >
            {helpertext}
          </FormHelperText>
        )}
      </FormControl>
    )
  }

  if (type === 'radio') {
    return (
      <InputCheckboxLabel
        {...props}
        size={size}
        data-testid={id}
        // checked={props.checked === true}
        control={
          <Radio
            color="default"
            disableRipple
            sx={{ '&:hover': { bgcolor: 'transparent' } }}
          />
        }
        sx={{
          marginRight: !label && 0,
          '& .MuiButtonBase-root': {
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: !label ? 0 : '9px',
          },
        }}
      />
    )
  }

  if (type === 'checkbox') {
    return (
      <InputCheckboxLabel
        {...props}
        size={size}
        data-testid={id}
        checked={props?.checked === true}
        control={
          <Checkbox
            sx={{ '&:hover': { bgcolor: 'transparent' } }}
            disableRipple
            color="default"
            checkedIcon={<CheckboxActiveIcon />}
            icon={<CheckboxIcon />}
          />
        }
        sx={{
          marginRight: !label && 0,
          '& .MuiButtonBase-root': {
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: !label ? 0 : '9px',
          },
        }}
      />
    )
  }

  const trickSkinkProps = {}
  if (!label && !!props.placeholder) _.set(trickSkinkProps, 'shrink', true)
  return (
    <FormControl {...props} fullWidth variant="outlined" size={size}>
      <InputLabel {...trickSkinkProps}>{label}</InputLabel>
      <OutlinedInput
        {...props}
        sx={{
          backgroundColor: 'white',
          ...(props?.sx || {}),
        }}
        size={size}
        data-testid={id}
        inputProps={{
          'data-cy': `${id}-input`,
          'data-testid': `${id}-input`,
        }}
        endAdornment={
          type === 'search' && (
            <InputAdornment position="end">
              <Search
                fontSize={size || 'inherit'}
                sx={{ color: disabled && '#00000038' }}
              />
            </InputAdornment>
          )
        }
      />

      {helpertext?.length && (
        <FormHelperText
          {...props}
          sx={{ ml: 0, color: '#3C4C70' }}
          data-cy={`${id}-error-msg`}
        >
          {helpertext}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default InputComponent
