import HTTPClient from '../..'
import { createCompanyEvent } from '@utils/metrics'
import { UserAuth } from '@services/nomos_api/entities/user'
import { Company } from '@services/nomos_api/entities/company'

export const createCompanyAPI = async (company: Company): Promise<UserAuth> => {
  const { data } = await HTTPClient.post<UserAuth>('companies', company)
  createCompanyEvent(company)
  return data
}
