import feedbacks from './types'
import { useMemo } from 'react'
import { FeedbackContext } from './context'
import { useParams } from 'react-router-dom'

export type FeedbackProps = {
  children: React.ReactNode
}

export function FeedbackController({ children }: FeedbackProps) {
  const { type } = useParams()

  const store = useMemo(
    () => ({
      data: feedbacks[type || 'default'],
    }),
    [type]
  )

  return (
    <FeedbackContext.Provider value={store}>
      {children}
    </FeedbackContext.Provider>
  )
}
