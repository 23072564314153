import UserData from './containers/UserData'
import { CrumbedPage } from '@components/templates'

export function MyAccountView() {
  return (
    <CrumbedPage
      id="my-account-page"
      data-cy="my-account-page"
      title="Minha conta"
      description=""
      paths={[
        {
          name: 'Página Inicial',
          location: '/',
        },
        {
          name: 'Minha Conta',
          location: '/my-account',
        },
      ]}
    >
      <UserData />
    </CrumbedPage>
  )
}
