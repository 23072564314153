import { Stack, Typography } from '@mui/material'
import { Button, TextLink } from '@components/atoms'
import { ModalStandard } from '@components/molecules'
import { useContextSelector } from 'use-context-selector'
import { ModalResendPhoneCodeContext } from './context'
import InputPhone from '@components/atoms/InputPhone'

export function ModalResendPhoneCodeView(): JSX.Element {
  const handleTroubleAccess = useContextSelector(
    ModalResendPhoneCodeContext,
    (s) => s.handleTroubleAccess
  )
  const formik = useContextSelector(
    ModalResendPhoneCodeContext,
    (s) => s.formik
  )
  return (
    <ModalStandard title="Não recebeu ou o código expirou?" closeable>
      <Stack spacing={3}>
        <Typography variant="$font-body-1" color="$color-text-secundary">
          Confira se digitou seu número de WhatsApp
          <br />
          corretamentamente:
        </Typography>
        <InputPhone
          id="phone"
          size="small"
          name="phone"
          label="Informe seu número"
          placeholder="(__) _____-____"
          value={formik.values.phone}
          onBlur={formik.handleBlur}
          onKeyUp={(e) => e.key === 'Enter' && formik.handleSubmit()}
          onChange={(value) => formik.setFieldValue('phone', value)}
          error={
            (formik.touched.phone &&
              formik.errors.phone) as unknown as undefined
          }
        />
        <Button
          type="button"
          size="large"
          text="Reenviar Código"
          onClick={() => formik.handleSubmit()}
          loading={formik.isSubmitting}
          disabled={!formik.isValid || formik.isSubmitting}
        />
        <Typography
          textAlign="center"
          variant="$font-body-base"
          color="$color-text-secundary"
        >
          Problemas para acessar ou criar a sua conta? Entre em
          <br />
          contato com nossa equipe através do link abaixo ou envie
          <br />
          um e-mail para: <strong>contato@nomosapp.com.br</strong>
        </Typography>
        <Stack alignItems="center">
          <TextLink
            onClick={handleTroubleAccess}
            text="Falar com especialista"
          />
        </Stack>
      </Stack>
    </ModalStandard>
  )
}
