import styled from 'styled-components'
import { Card } from '@components/atoms'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 40%;
  gap: 24px;
`

export const ResetPassowrdCard = styled(Card)`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 32px;
  gap: 32px;
`
