import styled from 'styled-components'
import { Card } from '@components/atoms'
import { RadioGroup } from '@mui/material'

export const Container = styled.div`
  gap: 32px;
  display: flex;
  padding: 32px;
  flex-direction: column;
  background-color: white;
`

export const Content = styled.div`
  gap: 32px;
  width: 100%;
  display: flex;
  flex-direction: row;
`

export const InfoLabel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 216px;
`

export const Roles = styled(RadioGroup)`
  display: flex;
  flex-direction: row !important;
  gap: 32px;
`

export const RoleCard = styled(Card)`
  display: flex;
  flex-direction: row;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 16px;
  padding-left: 16px;

  height: 100px;
  width: auto;
  max-width: 350px;
  flex: 1;
`

export const RoleLabel = styled.div`
  display: flex;
  flex-direction: column;
`

export const Inputs = styled.div`
  gap: 48px;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
`
