import { uniqueId } from 'lodash'
import { Shimmer } from '@components/atoms'
import { Breadcrumbs, Link } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

export type Path = {
  name: string
  location: string
}

export type BreadcrumbProps = {
  paths: Path[]
  isLoading?: boolean
}

function Breadcrumb({ paths, isLoading }: BreadcrumbProps) {
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="inherit" color="disabled" />}
      aria-label="breadcrumb"
    >
      {paths.map((path, index) => {
        return (
          <Shimmer
            key={uniqueId()}
            width={100}
            height={10}
            isLoading={isLoading || false}
          >
            <Link
              key={path.name}
              variant="$font-body-base"
              underline="none"
              color={index < paths.length - 1 ? 'muted.main' : 'secondary'}
              href={path.location}
            >
              {path.name}
            </Link>
          </Shimmer>
        )
      })}
    </Breadcrumbs>
  )
}

Breadcrumb.defaultProps = {
  isLoading: false,
}

export default Breadcrumb
