import { TextLink } from '@components/atoms'
import { CardMedia, CircularProgress, Typography } from '@mui/material'

import { FeedbackCard, Media } from './Feedback.styles'

export type FeedbackProps = {
  icon: string
  title: string
  text: string
  action?: {
    path: string
    text: string
  }
  isLoading?: boolean
}

function Feedback({ icon, title, text, action, isLoading }: FeedbackProps) {
  return (
    <FeedbackCard>
      <Media>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <CardMedia
            image={icon}
            title="Icon Circle Success"
            sx={{ height: '120px', width: '120px', alignSelf: 'center' }}
          />
        )}
      </Media>

      <Typography
        data-testid="title"
        variant="$font-body-base"
        sx={{
          color: '#0A2156',
          fontWeight: 700,
          fontSize: '40px',
          lineHeight: '48px',
        }}
      >
        {title}
      </Typography>

      <Typography
        data-testid="message"
        variant="subtitle1"
        sx={{
          color: '#858FA6',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '17px',
        }}
      >
        {text}
      </Typography>

      {action && <TextLink href={action.path} text={action.text} />}
    </FeedbackCard>
  )
}

Feedback.defaultProps = {
  isLoading: false,
}

export default Feedback
