export const fetchCasaOptions = async () => [
  { key: 'camara', value: 'Camara dos Deputados' },
  { key: 'senado', value: 'Senado Federal' },
  { key: 'almg', value: 'ALMG' },
  { key: 'alesp', value: 'ALESP' },
  { key: 'aleac', value: 'ALEAC'},
  { key: 'aleal', value: 'ALEAL'},
  { key: 'aleam', value: 'ALEAM'},
  { key: 'alema', value: 'ALEMA'},
  { key: 'alero', value: 'ALERO'},
  { key: 'alepe', value: 'ALEPE'},
  { key: 'alerj', value: 'ALERJ'},
  { key: 'alrs', value: 'ALRS'},
  { key: 'alesc', value: 'ALESC'},
  { key: 'alego', value: 'ALEGO'},
  { key: 'alepi', value: 'ALEPI'},
  { key: 'aleto', value: 'ALETO'},
  { key: 'alerr', value: 'ALERR'},
]
