import { MonitorLayoutContext } from '@layouts/monitor'
import {
  SidePageBodyContainer,
  SidePageContainer,
  SidePageLeftContainer,
  SidePageLeftContainerFixed,
  SidePagePull,
  SidePageRoot,
  SidePageWrapper,
} from './SidePage.styles'
import { useContextSelector } from 'use-context-selector'

export type SidePageProps = {
  id: string
  sideComponent: React.ReactChild
  headComponent?: React.ReactChild
  breadcrumbComponent?: React.ReactChild
  children: React.ReactChild
  leftVisible?: boolean
}

function SidePage({
  id,
  children,
  sideComponent,
  headComponent,
  breadcrumbComponent,
  leftVisible = true,
}: SidePageProps) {
  const top = useContextSelector(MonitorLayoutContext, (s) => s.paddingTop)
  return (
    <SidePageRoot id={id} data-testid={id}>
      {breadcrumbComponent && (
        <SidePageWrapper sx={{ height: 'auto' }}>
          <SidePagePull color="transparent" />
          <SidePageContainer sx={{ backgroundColor: 'transparent' }}>
            <SidePageBodyContainer sx={{ paddingLeft: 0, paddingTop: '24px' }}>
              {breadcrumbComponent}
            </SidePageBodyContainer>
          </SidePageContainer>
          <SidePagePull color="transparent" />
        </SidePageWrapper>
      )}
      {headComponent && (
        <SidePageWrapper sx={{ height: 'auto' }}>
          <SidePagePull color="transparent" />
          <SidePageContainer sx={{ backgroundColor: 'transparent' }}>
            {leftVisible && <SidePageLeftContainer />}
            <SidePageBodyContainer
              sx={{
                paddingLeft: 0,
                paddingBottom: '28px',
              }}
            >
              {headComponent}
            </SidePageBodyContainer>
          </SidePageContainer>
          <SidePagePull color="transparent" />
        </SidePageWrapper>
      )}
      <SidePageWrapper>
        <SidePagePull color={leftVisible ? 'transparent' : 'white'} />
        <SidePageContainer>
          {leftVisible && (
            <SidePageLeftContainer>
              <SidePageLeftContainerFixed sx={{ top: `${top}px` }}>
                {sideComponent}
              </SidePageLeftContainerFixed>
            </SidePageLeftContainer>
          )}
          <SidePageBodyContainer sx={{ paddingLeft: leftVisible ? '46px' : 0 }}>
            {children}
          </SidePageBodyContainer>
        </SidePageContainer>
        <SidePagePull color="white" />
      </SidePageWrapper>
    </SidePageRoot>
  )
}

export default SidePage
