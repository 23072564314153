import { FormRegistrationStep2Controller } from './controller'
import { FormRegistrationStep2Props } from './types'
import { FormRegistrationStep2View } from './view'

export function FormRegistrationStep2(
  props: FormRegistrationStep2Props
): JSX.Element {
  return (
    <FormRegistrationStep2Controller {...props}>
      <FormRegistrationStep2View />
    </FormRegistrationStep2Controller>
  )
}
