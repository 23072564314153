import { MyAccountContext } from '../../context'
import { Button, Input } from '@components/atoms'
import { useContextSelector } from 'use-context-selector'
import { Grid, Radio, Stack, Typography } from '@mui/material'

import {
  Container,
  Content,
  InfoLabel,
  Inputs,
  RoleCard,
  RoleLabel,
  Roles,
} from './styles'
import InputPhone from '@components/atoms/InputPhone'
import {
  NomosProductCodeEnum,
  NomosProductDesc,
  NomosProductRoleName,
} from '@enums/NomosProductEnum'
import { useAuth } from '@contexts/Auth'

export default function UserData() {
  const { user } = useAuth()
  const formik = useContextSelector(MyAccountContext, (s) => s.formik)

  return (
    <Container>
      <Content>
        <InfoLabel>
          <Typography variant="$font-title-5" color="$color-text-primary">
            Informações do usuário
          </Typography>
          <Typography variant="$font-body-base" color="$color-text-secundary">
            Consulte e gerencie suas <br /> informações pessoais
          </Typography>
        </InfoLabel>
        <Inputs>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Input
                id="name"
                name="name"
                size="small"
                data-cy="name"
                label="Nome do Usuário"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helpertext={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                id="email"
                size="small"
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helpertext={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={6}>
              <InputPhone
                id="phone"
                size="small"
                name="phone"
                label="Telefone"
                placeholder="(__) _____-____"
                value={formik.values.phone}
                onBlur={formik.handleBlur}
                onChange={(value) => formik.setFieldValue('phone', value)}
                error={formik.touched.phone && formik.errors.phone}
              />
            </Grid>
          </Grid>
          <Stack alignItems="flex-start">
            <Button
              data-cy="submit"
              type="button"
              text="Salvar alterações"
              disabled={formik.isSubmitting}
              loading={formik.isSubmitting}
              onClick={formik.handleSubmit}
            />
          </Stack>
        </Inputs>
      </Content>

      <Stack direction="column" spacing={2}>
        <Content>
          <InfoLabel>
            <Typography variant="$font-title-5" color="$color-text-primary">
              Perfil
            </Typography>
            <Typography variant="$font-body-base" color="$color-text-secundary">
              Consulte sua perfil de <br /> acesso ao Nomos
            </Typography>
          </InfoLabel>

          <Roles
            name="role-options-group"
            value={formik.values.admin}
            onChange={formik.handleChange}
          >
            <RoleCard type="bordored">
              <RoleLabel>
                <Typography
                  variant="$font-body-base"
                  color="$color-text-primary"
                >
                  Administrador
                </Typography>
                <Typography
                  variant="$font-body-base"
                  color="$color-text-secundary"
                >
                  Concede permissão para o usuário adicionar novos membros,
                  gerenciar a organização, cobranças e assinaturas.
                </Typography>
              </RoleLabel>
              <Radio value="true" checked={formik.values.admin} disabled />
            </RoleCard>

            <RoleCard type="bordored">
              <RoleLabel>
                <Typography
                  variant="$font-body-base"
                  color="$color-text-primary"
                >
                  Membro
                </Typography>
                <Typography
                  variant="$font-body-base"
                  color="$color-text-secundary"
                >
                  Apenas pode navegar pelos recursos disponíveis na nomos de
                  acordo com suas permissões.
                </Typography>
              </RoleLabel>
              <Radio value="false" checked={!formik.values.admin} disabled />
            </RoleCard>
          </Roles>
        </Content>

        <Content>
          <InfoLabel>
            <Typography variant="$font-title-5" color="$color-text-primary">
              Permissão
            </Typography>
            <Typography variant="$font-body-base" color="$color-text-secundary">
              Consulte sua permissão de <br /> acesso ao Nomos
            </Typography>
          </InfoLabel>

          <Roles name="role-options-group" value={formik.values.role}>
            <RoleCard type="bordored">
              <RoleLabel>
                <Typography
                  variant="$font-body-base"
                  color="$color-text-primary"
                >
                  {NomosProductRoleName[NomosProductCodeEnum.MONITOR_PRO]}
                </Typography>
                <Typography
                  variant="$font-body-base"
                  color="$color-text-secundary"
                >
                  {NomosProductDesc[NomosProductCodeEnum.MONITOR_PRO]}
                </Typography>
              </RoleLabel>
              <Radio
                checked={user?.role === NomosProductCodeEnum.MONITOR_PRO}
                disabled
              />
            </RoleCard>

            <RoleCard type="bordored">
              <RoleLabel>
                <Typography
                  variant="$font-body-base"
                  color="$color-text-primary"
                >
                  {NomosProductRoleName[NomosProductCodeEnum.MONITOR_LIGHT]}
                </Typography>
                <Typography
                  variant="$font-body-base"
                  color="$color-text-secundary"
                >
                  {NomosProductDesc[NomosProductCodeEnum.MONITOR_LIGHT]}
                </Typography>
              </RoleLabel>
              <Radio
                checked={user?.role === NomosProductCodeEnum.MONITOR_LIGHT}
                disabled
              />
            </RoleCard>
          </Roles>
        </Content>
      </Stack>
    </Container>
  )
}
