import { SigninController } from './controller'
import { SigninProps } from './types'
import { SigninView } from './view'

export default function Signin(props: SigninProps): JSX.Element {
  return (
    <SigninController {...props}>
      <SigninView />
    </SigninController>
  )
}
