import * as Yup from 'yup'
import { useMemo } from 'react'
import { FormRegistrationStep1Context } from './context'
import {
  FormRegistrationStep1ControllerProps,
  FormRegistrationStep1FormType,
} from './types'
import { useContextSelector } from 'use-context-selector'
import { SigninContext } from '../../context'
import { useFormik } from 'formik'
import { name, email, officePosition } from '@utils/forms/validation'

export function FormRegistrationStep1Controller({
  children,
}: FormRegistrationStep1ControllerProps): JSX.Element {
  const formikCtx = useContextSelector(SigninContext, (s) => s.formikCtx)

  const handleNextStep = useContextSelector(
    SigninContext,
    (s) => s.handleNextStep
  )

  const isInitialValid =
    !!formikCtx.values?.email &&
    !!formikCtx.values?.name &&
    !!formikCtx.values?.company?.about?.officePosition

  const formik = useFormik<FormRegistrationStep1FormType>({
    isInitialValid,
    initialValues: {
      name: formikCtx.values.name || '',
      email: formikCtx.values.email || '',
      officePosition: formikCtx.values.company.about.officePosition || '',
    },
    validationSchema: Yup.object().shape({
      name,
      email,
      officePosition,
    }),
    onSubmit({ name, email, officePosition }) {
      formikCtx.setFieldValue('name', name)
      formikCtx.setFieldValue('email', email)
      formikCtx.setFieldValue('company.about.officePosition', officePosition)
      handleNextStep()
    },
  })

  const state = useMemo(() => ({ formik }), [formik])
  return (
    <FormRegistrationStep1Context.Provider value={state}>
      {children}
    </FormRegistrationStep1Context.Provider>
  )
}
