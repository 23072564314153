import * as yup from 'yup'
import { useMemo } from 'react'
import { useFormik } from 'formik'
import { useModal } from '@contexts/Modal'
import { CompanyInfoModalContext } from './context'
import { createCompanyAPI } from '@services/nomos_api/resources/company/create'
import { useSnackbar } from '@contexts/Snackbar'
import {
  AxiosErrorResponse,
  FeedbackMessage,
} from '@services/nomos_api/entities/axios'
import { useAuth } from '@contexts/Auth'
import React from 'react'
import OnboardModalView from '@containers/OnboardModal'

type CompanyInfoModaControllerProps = {
  children: React.ReactNode
}

export default function CompanyInfoModalController({
  children,
}: CompanyInfoModaControllerProps) {
  const { cleanStack, openModal } = useModal()
  const { isAuthenticated } = useAuth()
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar()
  const [submitting, setSubmiting] = React.useState<boolean>(false)
  const [review, setReview] = React.useState<FeedbackMessage | undefined>(
    undefined
  )

  const handleTrackSingupCompleted = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const w = window as any
    if (w.smartlook) {
      w.smartlook('track', 'sign_up_completed')
    }
  }

  const validationSchema = yup.object({
    name: yup
      .string()
      .min(3, 'Deve ter no mínimo 3 caracteres')
      .required('Nome da empresa é obrigatório'),
    // document: yup
    //   .string()
    //   .min(14, 'Deve ter no mínimo 14 caracteres')
    //   .required('CNPJ é obrigatório'),
    aboutOfficePosition: yup.string().required('Cargo é obrigatório'),
    aboutSector: yup.string().required('Setor é obrigatório'),
    aboutEmployees: yup
      .string()
      .required('Quantidade de funcionários é obrigatório'),
  })

  const formik = useFormik({
    initialValues: {
      name: '',
      document: undefined,
      aboutSector: '',
      aboutEmployees: '',
      aboutOfficePosition: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setReview(undefined)
      setSubmiting(true)
      createCompanyAPI(values)
        .then((userSession) => {
          handleTrackSingupCompleted()
          isAuthenticated(userSession?.user, userSession.jwt)
          showSnackbarSuccess('Empresa criada com sucesso!')
          cleanStack()
          openModal(<OnboardModalView />, { lockclose: true })
        })
        .catch((error: AxiosErrorResponse) => {
          setReview(error.response?.data)
          showSnackbarError(
            error?.response?.data?.message ||
              'Erro ao atualizar informações sobre a empresa, favor revisar os campos'
          )
        })
        .finally(() => setSubmiting(false))
    },
  })

  const store = useMemo(
    () => ({ review, formik, submitting }),
    [review, formik, submitting]
  )

  return (
    <CompanyInfoModalContext.Provider value={store}>
      {children}
    </CompanyInfoModalContext.Provider>
  )
}
