import { SigninContext } from '../../context'
import { useContextSelector } from 'use-context-selector'
import { FormPhoneIdentifier } from '../FormPhoneIdentifier'
import { FormPhoneConfirmation } from '../FormPhoneConfirmation'
import { FormRegistrationStep1 } from '../FormRegistrationStep1'
import { FormRegistrationStep2 } from '../FormRegistrationStep2'

export function SignInFormSelector(): JSX.Element | null {
  const step = useContextSelector(SigninContext, (s) => s.step)
  if (step === 'phone_identifier') return <FormPhoneIdentifier />
  if (step === 'phone_confirmation') return <FormPhoneConfirmation />
  if (step === 'registration_step_1') return <FormRegistrationStep1 />
  if (step === 'registration_step_2') return <FormRegistrationStep2 />
  return null
}
