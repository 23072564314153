import { FormRegistrationStep1Controller } from './controller'
import { FormRegistrationStep1Props } from './types'
import { FormRegistrationStep1View } from './view'

export function FormRegistrationStep1(
  props: FormRegistrationStep1Props
): JSX.Element {
  return (
    <FormRegistrationStep1Controller {...props}>
      <FormRegistrationStep1View />
    </FormRegistrationStep1Controller>
  )
}
