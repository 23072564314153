import HTTPClient from '../..'
import { useMutation } from '@tanstack/react-query'
import { UserAuth } from '@services/nomos_api/entities/user'
import { AxiosErrorResponse } from '@services/nomos_api/entities/axios'
import { FormSignUpType } from '@services/nomos_api/types/form_signup_type'

export const authSignUpAPI = async (body: FormSignUpType) => {
  const { data } = await HTTPClient.post<UserAuth>('/signup', body)
  return data
}

export const useAuthSignUp = () => {
  return useMutation<UserAuth, AxiosErrorResponse, FormSignUpType>(
    ['auth-signup'],
    authSignUpAPI
  )
}
