import { Stack, Typography } from '@mui/material'
import { Button, TextLink } from '@components/atoms'
import { useContextSelector } from 'use-context-selector'
import InputPinCode from '@components/atoms/InputPinCode'
import { FormPhoneConfirmationContext } from './context'
import { SignInForm } from '../../styles'
import { SigninContext } from '../../context'
import { TextTimeOut } from '@components/atoms/TextTimeOut/TimeOut'

export function FormPhoneConfirmationView(): JSX.Element {
  const formikCtx = useContextSelector(SigninContext, (s) => s.formikCtx)
  const formikReq = useContextSelector(SigninContext, (s) => s.formikRequest)
  const formik = useContextSelector(
    FormPhoneConfirmationContext,
    (s) => s.formik
  )

  const handleOnExpire = useContextSelector(
    SigninContext,
    (s) => s.handleOnExpire
  )

  const handleRetry = useContextSelector(
    FormPhoneConfirmationContext,
    (s) => s.handleRetry
  )

  const CODE_SIZE = 5
  return (
    <SignInForm data-cy="signin-step-phone-confirmation">
      <Stack direction="column" spacing={1} alignItems="center">
        <Typography
          textAlign="center"
          variant="$font-title-3"
          color="$color-text-primary"
        >
          Insira o código
        </Typography>
        <Typography
          textAlign="center"
          variant="$font-body-base"
          color="$color-text-secundary"
        >
          Inseria o código de {CODE_SIZE} dígitos enviado via WhatsApp
          <br />
          para o número{` `}
          <strong>{formikCtx.values.phone}</strong>
        </Typography>
      </Stack>
      <Stack direction="column" spacing={1} sx={{ width: '100%' }}>
        <InputPinCode
          id="phone-code"
          length={CODE_SIZE}
          value={formik.values.request_code}
          onChange={(code) => formik.setFieldValue('request_code', code)}
          onPressEnter={() => formik.handleSubmit()}
          error={
            ((formik.touched.request_code && formik.errors.request_code) ||
              (formik.touched.request_id &&
                formik.errors.request_id)) as unknown as undefined
          }
        />
        <Stack alignItems="center">
          <TextTimeOut
            onTimedOut={handleOnExpire}
            expireTime={formikReq.values.exp}
            countDownLabelTemplate="Sua sessão expira em {{time}}"
            timedOutLabelTemplate="Sua sessão expirou"
          />
        </Stack>
      </Stack>
      <Button
        id="btn-submit-confirm-code"
        type="button"
        size="large"
        sx={{ px: 6, width: { xs: '100%', sm: 'auto' } }}
        variant="contained"
        text="Confirmar"
        onClick={() => formik.handleSubmit()}
        loading={formik.isSubmitting}
        disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
      />
      <TextLink
        text="Problemas ou não recebeu o código enviado?"
        underline="hover"
        onClick={handleRetry}
      />
    </SignInForm>
  )
}
